import React from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import Ctasection from '../../containers/Hosting/Ctasection';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';

import BannerSectionSolution from '../../containers/Hosting/Banner/BannerSolution';
import InfoSolutionSection from '../../containers/Hosting/InfoSolution';
import TopRibbon from '../../containers/Hosting/TopRibbon';

const CrossIndustryPage = () => {
  const name = 'CrossIndustry';
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Automation platform for cross-industry"
          description="The RAPFlow Platform can be used for the automation of data-intensive and repetitive tasks for any industry. Book a free demo here to know more."
        />

        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="CrossIndustryPage">
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSolution name={name} />
          <InfoSolutionSection name={name} />
          <Ctasection name={name} />
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

export default CrossIndustryPage;
